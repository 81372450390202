export default {
  filterArr: [
    {
      title: "统计条件",
      filterList: [
        {
          key: "manufacturerId",
          type: "select",
          keyWord: "厂商名称",
        },
        {
          key: "firstCategory",
          type: "select",
          keyWord: "设备大类",
          tenantType: "0",
        },
        {
          key: "secondCategory",
          type: "select",
          keyWord: "设备小类",
          tenantType: "0",
        },
        {
          key: "userType",
          type: "select",
          keyWord: "客户类型",
          tenantType: "1",
        },
        {
          key: "dateSection",
          type: "date",
          keyWord: "日期区间",
          icon: "icon-shijian",
          datePickerType: "datetimerange",
          clearable: false,
        },
        {
          key: "deviceTypeId",
          type: "select",
          keyWord: "设备类型",
          tenantType: "0",
          filterable: true,
        },
        {
          key: "alarmType",
          type: "select",
          keyWord: "告警类型",
        },
      ],
    },
  ],
  columns: [
    {
      key: "deviceTypeName",
      name: "设备类型",
      tenantType: "0",
    },
    {
      key: "userTypeName",
      name: "客户类型",
      tenantType: "1",
    },
    {
      key: "alarmTypeName",
      name: "告警类型",
    },
    {
      key: "date",
      name: "日期",
    },
    {
      key: "alarmAmounts",
      name: "告警总量",
      hasTotal: true,
    },
    {
      key: "treatedAmounts",
      name: "已处理量",
      hasTotal: true,
    },
    {
      key: "untreatedAmounts",
      name: "未处理量",
      hasTotal: true,
    },
  ],
  header: {
    pic: "statistical-analysis",
    title: "告警处理情况统计",
    desc: "对当前告警的处理情况进行统计分析。",
  },
};
