// 多天不上传统计
export default {
  header: {
    pic: "statistical-analysis",
    title: "阀门操作统计",
    desc: "对设备阀门的各种操作记录进行统计记录。",
  },
  filterArr: [
    {
      title: "统计条件",
      filterList: [
        {
          key: "deviceNo",
          keyWord: "设备编号",
        },
        // {
        //   key: "userNo",
        //   keyWord: "用户号",
        // },
        // {
        //   key: "residentType",
        //   type: "select",
        //   keyWord: "用户类型",
        // },
        {
          key: "deviceTypeId",
          type: "select",
          keyWord: "设备类型",
        },
        // {
        //   key: "manufacturerId",
        //   type: "select",
        //   keyWord: "厂商名称",
        // },
        {
          key: "dateSection",
          type: "date",
          keyWord: "时间范围",
          icon: "icon-shijian",
          datePickerType: "datetimerange",
        },
        // {
        //   key: "operator",
        //   type: "select",
        //   keyWord: "运营商",
        // },
        {
          key: "eventSource",
          type: "select",
          keyWord: "操作来源",
        },
        {
          key: "eventName",
          type: "select",
          keyWord: "操作类型",
        },
      ],
    },
  ],
  columns: [
    {
      key: "deviceNo",
      name: "设备编号",
    },
    // {
    //   key: "userNo",
    //   name: "用户号",
    // },
    // {
    //   key: "residentType",
    //   name: "用户类型",
    // },
    {
      key: "deviceTypeName",
      name: "设备类型",
    },
    // {
    //   key: "manufacturerName",
    //   name: "厂商名称",
    // },
    {
      key: "optTime",
      name: "操作时间",
    },
    // {
    //   key: "operator",
    //   name: "运营商",
    // },
    {
      key: "eventSource",
      name: "操作来源",
    },
    {
      key: "eventName",
      name: "操作类型",
    },
  ],
};
