export default {
  filterArr: [
    {
      title: "统计条件",
      filterList: [
        {
          key: "firstCategory",
          type: "select",
          keyWord: "设备大类",
        },
        {
          key: "secondCategory",
          type: "select",
          keyWord: "设备小类",
        },
        {
          key: "dateSection",
          type: "date",
          keyWord: "日期区间",
          icon: "icon-shijian",
          datePickerType: "datetimerange",
        },
        {
          key: "alarmType",
          type: "select",
          keyWord: "告警类型",
        },
      ],
    },
  ],
  columns: [
    {
      key: "manufacturerName",
      name: "厂商名称",
    },
    {
      key: "secondCategory",
      name: "设备小类",
    },
    {
      key: "date",
      name: "日期",
    },
    {
      key: "alarmTypeName",
      name: "告警类型",
    },
    {
      key: "deviceAmounts",
      name: "设备总量",
    },
    {
      key: "alarmDeviceAmounts",
      name: "告警设备量",
    },
    {
      key: "alarmPercentage",
      name: "告警比例",
    },
  ],
  header: {
    pic: "statistical-analysis",
    title: "厂商告警设备对比",
    desc: "对厂商之间的设备告警情况进行统计分析。",
  },
};
