export default {
  filterArr: [
    {
      title: "统计条件",
      filterList: [
        {
          key: "manufacturerId",
          type: "select",
          keyWord: "厂商名称",
        },
        {
          key: "firstCategory",
          type: "select",
          keyWord: "设备大类",
          tenantType: "0",
        },
        {
          key: "secondCategory",
          type: "select",
          keyWord: "设备小类",
          tenantType: "0",
        },
        {
          key: "userType",
          type: "select",
          keyWord: "客户类型",
          tenantType: "1",
        },
        {
          key: "dateSection",
          type: "date",
          keyWord: "日期区间",
          icon: "icon-shijian",
          datePickerType: "datetimerange",
          clearable: false,
        },
      ],
    },
  ],
  columns: [
    {
      key: "manufacturerName",
      name: "厂商名称",
      width: 200,
      tooltip: true,
    },
    {
      key: "firstCategory",
      name: "设备大类",
      tenantType: "0",
    },
    {
      key: "secondCategory",
      name: "设备小类",
      tenantType: "0",
    },
    {
      key: "deviceTypeName",
      name: "设备类型",
    },
    {
      key: "userTypeName",
      name: "客户类型",
      tenantType: "1",
    },
    {
      key: "amounts",
      name: "总量",
      hasTotal: true,
    },
    {
      key: "waitInstallAmounts",
      name: "待安装量",
      hasTotal: true,
    },
    {
      key: "installedAmounts",
      name: "安装量",
      hasTotal: true,
    },
    {
      key: "removedAmounts",
      name: "已拆除",
      hasTotal: true,
    },
  ],
  header: {
    pic: "statistical-analysis",
    title: "设备发展量统计",
    desc: "对当前设备的发展情况进行统计分析。",
  },
};
