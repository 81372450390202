import request from "./request";
import { Message } from "element-ui";

// 报表导出公共方法
export function exportReport(data, type, others, loading) {
  request({
    method: data.method,
    url: data.url,
    data: data.data,
    responseType: "blob",
    timeout: 300000,
    ...others,
  })
    .then((res) => {
      if (loading) {
        loading.close && loading.close();
        loading.clear && loading.clear();
      }
      const link = document.createElement("a");
      if (type == "pdf") {
        let blob = new Blob([res], { type: "application/pdf" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = data.fileName; //下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (type == "excel") {
        let blob = new Blob([res], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = data.fileName; //下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(() => {
      if (loading) {
        loading.close && loading.close();
        loading.clear && loading.clear();
      }
      // console.log(`导出报表失败-${error}`);
      Message.error("导出报表失败");
    });
}

// 设备发展量统计
export const apiGetDeviceDevelopList = (data) => {
  return request({
    url: "/monitor/report/device-develop/preview",
    method: "POST",
    data,
    pending: true,
  });
};

// 告警处理情况统计
export const apiGetAlarmProcessList = (data) => {
  return request({
    url: "/monitor/report/alarm-process/preview",
    method: "POST",
    data,
    pending: true,
  });
};

// 厂商告警设备统计
export const apiGetDeviceAlarmList = (data) => {
  return request({
    url: "/monitor/report/device-alarm/preview",
    method: "POST",
    data,
    pending: true,
  });
};

// 查询设备大类分类列表
export const apiGetFirstCategoryList = (data) => {
  return request({
    url: "/monitor/product-category/list/first",
    method: "GET",
    data,
    pending: true,
  });
};

// 根据设备大类编码查询设备小类列表
export const apiGetSecondCategoryList = (productCategoryId) => {
  return request({
    url: `/monitor/product-category/list/${productCategoryId}`,
    method: "GET",
    pending: true,
  });
};

// 获取设备厂商列表
export const apiGetManufacturerList = (data) => {
  return request({
    url: "/monitor/manufacturer/list",
    method: "POST",
    data,
    pending: true,
  });
};

// 获取已授予和已撤销的设备类型列表
export const apiGetDeviceTypeList = (tenantId) => {
  return request({
    url: `/monitor/deviceType/list?tenantId=${tenantId}`,
    method: "GET",
    pending: true,
  });
};

// 获取告警类型
export const apiGetEventModelList = () => {
  return request({
    url: `/monitor/event-model/list`,
    method: "GET",
    pending: true,
  });
};

// 用气统计报表--客户类型
export const apiGasReportArchiveType = (data) => {
  return request({
    url: "/monitor/report/gas-report/statistics/archive-type/preview",
    method: "POST",
    data,
    pending: true,
  });
};

// 用气统计报表--汇总
export const apiGasReportStatistics = (data) => {
  return request({
    url: "/monitor/report/gas-report/statistics/archive/preview",
    method: "POST",
    data,
    pending: true,
  });
};

// 用气统计报表--明细
export const apiGasReportDetail = (data) => {
  return request({
    url: "/monitor/report/gas-report/detail/day-gas/preview",
    method: "POST",
    data,
    pending: true,
  });
};

// 抄表率报表
export const apiReportMeterReading = (data) => {
  return request({
    url: "/monitor/report/meter-reading/preview",
    method: "POST",
    data,
  });
};

// 不上传报表预览
export const apiReportNoUpload = (data) => {
  return request({
    url: "/monitor/report/exception/no-upload/preview",
    method: "POST",
    data,
  });
};

// 阀门操作报表预览
export const apiReportValve = (data) => {
  return request({
    url: "/monitor/report/exception/valve-opt/preview",
    method: "POST",
    data,
  });
};

// 大表气量分析 区域树
export const apiGetBigWatchRegionTree = (data) => {
  return request({
    url: "/monitor/water/region/buildFlagTree",
    method: "POST",
    data,
  });
};

// 大表气量分析 设备明细
export const apiGetDeviceNode = (data) => {
  return request({
    url: "/monitor/water/region/searchDeviceList",
    method: "POST",
    data,
  });
};

// 大表气量分析 列表请求
export const apiGetBigWatchTableList = (data) => {
  return request({
    url: "/monitor/water/largeScale/preview",
    method: "POST",
    data,
  });
};

export const apiGetBigWatchPreviewLine = (data) => {
  return request({
    url: "/monitor/water/largeScale/previewLineChat",
    method: "POST",
    data,
  });
};

// 小表气量分析 列表请求
export const apiGetSmallWatchTableList = (data) => {
  return request({
    url: "/monitor/report/water/smallScale/preview",
    method: "POST",
    data,
  });
};

export const apiGetStaticsDeviceDevelop = (data) => {
  return request({
    url: "/monitor/report/device-develop/staticsDeviceDevelop",
    method: "POST",
    data,
  });
};

export const apiGetStaticsAlarmProcess = (data) => {
  return request({
    url: "/monitor/report/alarm-process/staticsAlarmProcess",
    method: "POST",
    data,
  });
};
